
import Vue from "vue";
import { Session } from "@/types/interfaces";

export default Vue.extend({
  name: "CancelSessionModal",
  props: {
    session: {
      type: Object as () => Session
    }
  },
  data() {
    return {
      infoData: [
        {
          name: "price",
          label: "You have Paid",
          icon: "profile",
          color: "color-1",
          value: this.session.price
        },
        {
          name: "cancellationFee",
          label: "Cancellation Fee",
          icon: "price",
          color: "danger",
          value: this.session.cancellationFee
        },
        {
          name: "returned",
          label: "Returned to you",
          icon: "payment",
          color: "light-blue",
          value: "$ 45"
        }
      ]
    };
  }
});
